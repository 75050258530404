require('./npHeaderBar.scss');

class NpHeaderBar {
  constructor($ngRedux, settings) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.settings = settings;
    this.user = {};
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        user: state.user,
      };
    })(this);
  }

  async $onDestroy() {
    this.unsubscribe();
  }

  logout() {
    return `${this.settings.oauth.oauthURL}/logout?redirect_uri=${window.location.href}`;
  }
}

module.exports = {
  template: require('./npHeaderBar.html'),
  controllerAs: 'ctrl',
  transclude: true,
  controller: NpHeaderBar,
};
