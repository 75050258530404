require('./appRoot.scss');

class appRoot {
  constructor() {
    'ngInject';
  }

  $onInit() {}
}

module.exports = {
  template: require('./appRoot.html'),
  controllerAs: 'ctrl',
  controller: appRoot,
};
