import * as ACTION_TYPES from '../constants/actionTypes';

// export const fetchMailJobs = results => ({
//   type: ACTION_TYPES.FETCH_MAIL_JOBS,
//   payload: results,
// });

// export const fetchMailJobsSuccessful = mails => ({
//   type: ACTION_TYPES.FETCH_MAIL_JOBS_SUCCESSFUL,
//   payload: mails,
// });

export const checkMails = (results) => ({
  type: ACTION_TYPES.CHECK_MAILS,
  payload: results,
});

export const checkMailsSuccessful = (mails) => ({
  type: ACTION_TYPES.CHECK_MAILS_SUCCESSFUL,
  payload: mails,
});

export const startDeltaSync = (status) => ({
  type: ACTION_TYPES.START_DELTA_SYNC,
  payload: status,
});

export const deltaSyncStarted = (deltaSyncId) => ({
  type: ACTION_TYPES.DELTA_SYNC_STARTED,
  payload: deltaSyncId,
});

export const endDeltaSync = (mails) => ({
  type: ACTION_TYPES.END_DELTA_SYNC,
  payload: mails,
});
