require('./npCheckboxList.scss');

class NpCheckboxList {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {
    this.showMore = angular.isDefined(this.sShowMore);
    this.limit = this.sLimit || 20;
    this.showAll = false;
  }

  change(item) {
    this.$scope.$emit('checkboxList::change', item);
  }

  selectAll() {
    this.$scope.$emit('checkboxList::selectAll');
  }

  clearAll() {
    this.$scope.$emit('checkboxList::clearAll');
  }

  toggleShowMore() {
    this.showAll = !this.showAll;
    this.limit = this.showAll ? this.sOptions.length : this.sLimit;
  }
}

module.exports = {
  template: require('./npCheckboxList.html'),
  controllerAs: 'ctrl',
  controller: NpCheckboxList,
  bindings: {
    sOptions: '<',
    sShowMore: '@',
    sLimit: '<',
  },
};
