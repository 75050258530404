import * as VIEW_OPTIONS_ACTIONS from '../../../reduxLoop/actions/viewOptionsActions';

require('./npCoverage.scss');

class NpCoverage {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.regions = [];
    this.option = 'specific';
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const selectedRegions = state.viewOptions.coverage.filter((region) => region.checked);
      return {
        regions: state.viewOptions.coverage,
        selectedRegions,
        option: selectedRegions.length === state.viewOptions.coverage.length ? 'all' : 'specific',
      };
    })(this);
  }

  async $onDestroy() {
    this.unsubscribe();
  }

  selectRegion(item) {
    const region = { ...item, checked: true };
    this.regionsChanged(region);
  }

  removeRegion(item) {
    const region = { ...item, checked: false };
    this.regionsChanged(region);
  }

  regionsChanged(item) {
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.setCoverage(item));
  }

  clearAll() {
    this.selectedRegions = [];
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.unselectAll('coverage'));
  }

  selectAll() {
    this.selectedRegions = this.regions;
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.selectAll('coverage'));
  }
}

export default {
  template: require('./npCoverage.html'),
  controllerAs: 'ctrl',
  controller: NpCoverage,
};
