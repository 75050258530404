import { interceptFactory } from '@kathondvla/fetch-oauth-interceptor';
import fetchClient from '@kathondvla/sri-client/fetch-sri-client';
import { settings } from '../../config/settings';

export const sriClient = fetchClient({
  baseUrl: settings.apisAndUrls.api,
});

export const contentApi = fetchClient({
  baseUrl: settings.apisAndUrls.contentApi,
});

export const newsletterApi = fetchClient({
  baseUrl: settings.apisAndUrls.newsletterApi,
});

export const cachedSriClient = fetchClient({
  baseUrl: settings.apisAndUrls.cachedApi,
});

export const securityClient = fetchClient({
  baseUrl: settings.apisAndUrls.security,
});

export const oauthClient = fetchClient({
  baseUrl: settings.oauth.oauthURL,
});

export const newsletterClient = fetchClient({
  baseUrl: settings.apisAndUrls.newsletter,
});

const getAuthenticateUrl = () => `${settings.oauth.authenticate}
  ?scope=${settings.oauth.scope}
  &response_type=none&client_id=${settings.oauth.clientID}
  &redirect_uri=${settings.oauth.redirectUri}
  &state=${encodeURIComponent(window.location.href)}`;

export const goToOauthLogin = () => {
  const redirectTo = getAuthenticateUrl();
  console.log('redirect to', redirectTo);
  window.location.href = redirectTo;
};

export const goToLogOut = async () => {
  await oauthClient.getRaw('', undefined, {
    baseUrl: settings.oauth.logOut,
    credentials: 'include',
  });
  goToOauthLogin();
};

export const initApiConfig = () => {
  interceptFactory({
    urlPatterns: new RegExp(`${settings.apisAndUrls.api}|${settings.apisAndUrls.newsletter}`, 'g'),
    oauth: settings.oauth,
    state: window.location.href,
    ignore: {
      get: [
        /\/positions/g,
        /\/sam\/organisationalunits/g,
        /\/contactdetails/g,
        /\/content\/.*\/hash/g,
        /\/search/g,
      ],
      post: [
        /\/persons\/register/g,
        /\/persons\/generateusername/g,
        /\/persons\/contact-helpdesk/g,
      ],
    },
  });
};
