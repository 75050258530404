/* eslint-disable max-len */
/* eslint-disable func-names */

export default ($stateProvider, $urlRouterProvider, $locationProvider, ngMetaProvider) => {
  $locationProvider.html5Mode(true);

  $urlRouterProvider.when('', '/');
  $urlRouterProvider.otherwise(($injector) => {
    console.log('not found');
    const t = $injector.get('$state');
    console.log(t);
  });

  /* Navigation */
  $stateProvider
    .state('root', {
      cache: false,
      url: '/',
      component: 'npNewsletter',
    })
    .state('preview', {
      cache: false,
      url: '/{root}',
      component: 'npNewsletter',
    });

  ngMetaProvider.setDefaultTitle('Katholiek Onderwijs Vlaanderen');
  ngMetaProvider.setDefaultTag(
    'image',
    `https://${window.location.host}/dist/images/placeholder-banner.png`
  );
  ngMetaProvider.setDefaultTag(
    'description',
    'De pro. is de website voor de onderwijsprofessional'
  );
};
