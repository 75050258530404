import { loop, Cmd } from 'redux-loop';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as USER_COMMANDS from '../commands/userCommands';
import * as SECURITY_COMMANDS from '../commands/securityCommands';
import * as ACTIONS from '../actions/userActions';
import * as VIEW_OPTIONS_ACTIONS from '../actions/viewOptionsActions';
import { getInitials } from '../helpers/userHelper';

const inititalState = {};

export default (state = inititalState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_USER: {
      if (state.user && !action.payload) {
        return state;
      }
      return loop(
        state,
        Cmd.run(USER_COMMANDS.getUserCmd, {
          args: [],
          successActionCreator: ACTIONS.fetchUserSuccessful,
        })
      );
    }

    case ACTION_TYPES.FETCH_USER_SUCCESSFUL: {
      const initials = getInitials(action.payload);
      const $$profilePicture =
        action.payload.$$attachments &&
        action.payload.$$attachments.filter(
          (e) => e.$$expanded.type === 'PROFILE_IMAGE' && e.$$expanded.name === 'profile-small.jpg'
        )[0];

      return loop(
        {
          ...state,
          ...action.payload,
          initials,
          $$profilePicture,
        },
        Cmd.list([
          Cmd.action(VIEW_OPTIONS_ACTIONS.loadViewOptions()),
          Cmd.action({ type: ACTION_TYPES.IS_ALLOWED_TO_SEND }),
        ])
      );
    }

    case ACTION_TYPES.LOG_OUT:
      return loop(inititalState, Cmd.run(USER_COMMANDS.doLogOut));

    case ACTION_TYPES.IS_ALLOWED_TO_SEND:
      return loop(
        state,
        Cmd.run(SECURITY_COMMANDS.isAllowedToSend, {
          args: [state.key],
          successActionCreator: (result) => ({
            type: ACTION_TYPES.IS_ALLOWED_TO_SEND_SUCCESS,
            payload: { isAllowedToSend: result },
          }),
        })
      );

    case ACTION_TYPES.IS_ALLOWED_TO_SEND_SUCCESS:
      return {
        ...state,
        isAllowedToSend: action.payload.isAllowedToSend,
      };

    default:
      return state;
  }
};
