import React from 'react';

const Checkbox = ({ id, label, checked, className = '', disabled, onChange }) => {
  const onChangeHandler = (ev) => {
    onChange(!ev.target.checked);
  };

  return (
    <div className={`checkbox-row ${className || ''}`}>
      <input
        id={id}
        type="checkbox"
        checked={checked === undefined ? false : checked}
        disabled={disabled}
        onChange={onChangeHandler}
      />
      <label className="option" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
