import { loop, combineReducers } from 'redux-loop';
import { router } from 'redux-ui-router';
import viewOptionsReducer from './viewOptionsReducer';
import configurationsReducer from './configurationsReducer';
import urlReducer from './urlReducer';
import previewReducer from './previewReducer';
import userReducer from './userReducer';
import mailsReducer from './mailsReducer';
import { settings } from '../../config/settings';

export const rootReducer = (state, action) => {
  if (settings.logging.redux) {
    console.log(`%c[ACTION]`, 'background: #fff9c4', ` ${action.type}`, action);
  }

  const rawStateReducer = combineReducers({
    router,
    viewOptions: viewOptionsReducer,
    configuration: configurationsReducer,
    url: urlReducer,
    preview: previewReducer,
    user: userReducer,
    mails: mailsReducer,
  });

  const newLoop = rawStateReducer(state, action, state);
  const newState = newLoop[0];
  // const vmState = { ...newState, vm: viewModel(newState, action) };
  const vmState = { ...newState };

  if (settings.logging.redux) {
    console.log(`%c[STATE]`, 'background: #bbdefb', ` after ${action.type}`, vmState);
  }
  return loop(vmState, newLoop[1]);
};
