require('./npPreview.scss');

class NpPreview {
  constructor($ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      this.renderHtml(state.preview.html);
      return {
        url: state.preview.url,
        loading: state.preview.loading,
        showError: state.preview.showError,
        previewError: state.preview.previewError,
      };
    })(this);
  }

  async $onDestroy() {
    this.unsubscribe();
  }

  renderHtml(html) {
    if (html && this.oldHtml !== html) {
      document.querySelector('.preview-wrapper').innerHTML = html;
      this.oldHtml = html;

      document.querySelectorAll('.toc-link').forEach((el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          window.location.hash = '';
          window.location.hash = e.target.hash;
        });
      });
    }
  }
}

module.exports = {
  template: require('./npPreview.html'),
  controllerAs: 'ctrl',
  transclude: true,
  controller: NpPreview,
};
