import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CurriculumSelectorButton from './CurriculumSelectorButton/CurriculumSelectorButton';
import CurriculumSelectorModal from './CurriculumSelectorModal/CurriculumSelectorModal';
import CurriculumSelectorListOfSubjects from './CurriculumSelectorListOfSubjects/CurriculumSelectorListOfSubjects';
import * as VIEW_OPTIONS_ACTIONS from '../../../reduxLoop/actions/viewOptionsActions';

require('./CurriculumSelector.scss');

const CurriculumSelector = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const curriculumThemes = useSelector((state) => state.viewOptions.curriculumThemes) || [];
  const nonCurriculumThemes = useSelector((state) => state.viewOptions.nonCurriculumThemes) || [];
  const listOfSubjects = [...curriculumThemes, ...nonCurriculumThemes];
  const currentSelection = listOfSubjects.filter((theme) => theme.checked) || [];

  const dispatch = useDispatch();

  const openCurriculumSelectorModal = async (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const submitCurriculumSelectorModal = (data) => {
    dispatch(VIEW_OPTIONS_ACTIONS.setSubjects(data));

    setIsModalOpen(false);
  };

  const onClearAll = (type) => dispatch(VIEW_OPTIONS_ACTIONS.unselectAll(type));
  const onSelectAll = (type) => dispatch(VIEW_OPTIONS_ACTIONS.selectAll(type));

  return (
    <div className="curriculum-selector-wrapper">
      <div className="leerplannen-wrapper">
        <div className="form-group namedSet">
          <div>
            <label className="control-label">Leerplannen</label>
          </div>

          {currentSelection.some(
            (subject) => subject.type === 'CURRICULUM_THEME' && subject.checked === true
          ) ? (
            <div>
              <CurriculumSelectorListOfSubjects
                subjects={currentSelection.filter((subject) => subject.type === 'CURRICULUM_THEME')}
                showMoreOption
                type={'curriculumThemes'}
              />
            </div>
          ) : (
            <div className="col-sm-12 no-selected-leerplannen">
              {'Je hebt nog geen leerplannen geselecteerd.'}
            </div>
          )}
          <div className="col-md-12 select-all-actions">
            <a className="select-action" onClick={() => onClearAll('curriculumThemes')}>
              Alles wissen
            </a>{' '}
            /
            <a className="select-action" onClick={() => onSelectAll('curriculumThemes')}>
              Alles selecteren
            </a>
          </div>
        </div>
      </div>

      <div className="vakken-wrapper">
        <div className="form-group namedSet">
          <div>
            <label className="control-label">Vakken en studiegebieden (oude structuur)</label>
          </div>

          {currentSelection.some(
            (subject) => subject.type !== 'CURRICULUM_THEME' && subject.checked === true
          ) ? (
            <div>
              <CurriculumSelectorListOfSubjects
                subjects={currentSelection.filter((subject) => subject.type !== 'CURRICULUM_THEME')}
                selectAllOption
                showMoreOption
                type={'nonCurriculumThemes'}
              />
            </div>
          ) : (
            <div className="col-sm-12 no-selected-leerplannen">
              {'Je hebt nog geen vakken of studiegebieden geselecteerd.'}
            </div>
          )}

          <div className="col-md-12 select-all-actions">
            <a className="select-action" onClick={() => onClearAll('nonCurriculumThemes')}>
              Alles wissen
            </a>{' '}
            /
            <a className="select-action" onClick={() => onSelectAll('nonCurriculumThemes')}>
              Alles selecteren
            </a>
          </div>
        </div>
      </div>

      <CurriculumSelectorButton
        icon="glyphicon-plus"
        label="Pas deze selectie aan"
        onClick={(event) => openCurriculumSelectorModal(event)}
      />

      {isModalOpen && (
        <CurriculumSelectorModal
          handleSubmit={submitCurriculumSelectorModal}
          handleClose={() => setIsModalOpen(false)}
          selectedThemes={listOfSubjects
            .filter((theme) => theme.checked)
            .map((theme) => theme.href)}
        />
      )}
    </div>
  );
};

export default CurriculumSelector;
