import { sriClient, oauthClient } from '../api/apiConfig';

export const getUserCmd = async () => {
  const me = await oauthClient.get('/me', undefined, { credentials: 'include' });
  return sriClient.get(`/persons/${me.uuid}`);
};

export const doLogOut = async () => {
  // TODO
};
