require('./npNewsletter.scss');

class NpNewsletter {
  constructor() {
    'ngInject';
  }

  $onInit() {}
}

module.exports = {
  template: require('./npNewsletter.html'),
  controllerAs: 'ctrl',
  controller: NpNewsletter,
};
