/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as COMMANDS from '../commands/previewCommands';
import * as ACTIONS from '../actions/previewActions';
import * as MAILS_ACTIONS from '../actions/mailsActions';
import { getKeyFromPath } from '../helpers/urlHelper';

const initialState = {
  valid: false,
  key: getKeyFromPath(),
  url: null,
  html: null,
  loading: true,
  showError: false,
  previewError: null,
  testPreviewSuccess: null,
  sendTestPreviewTo: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SHOW_ERROR: {
      console.error(action.payload.error);
      return {
        ...state,
        loading: false,
        showError: true,
        previewError: action.payload.error ? action.payload.error : null,
      };
    }

    case ACTION_TYPES.FETCH_PREVIEW_SUCCESSFUL: {
      return {
        ...state,
        loading: false,
        showError: false,
        html: action.payload,
      };
    }

    case ACTION_TYPES.SET_PREVIEW_URL: {
      const params = COMMANDS.setPreviewUrl(action.payload);
      return loop(
        {
          ...state,
          ...params,
        },
        Cmd.action(ACTIONS.startDedounceFetchPreview())
      );
    }

    case ACTION_TYPES.VALIDATE: {
      return loop(
        {
          ...state,
          valid: action.payload != null ? action.payload : null,
        },
        Cmd.run(COMMANDS.validateCmd, {
          args: [state.key],
          successActionCreator: ACTIONS.validateSuccessful,
          failActionCreator: (error) => ({
            type: ACTION_TYPES.SHOW_ERROR,
            payload: { error: `[ERROR] checking validity: ${error}` },
          }),
        })
      );
    }

    case ACTION_TYPES.VALIDATE_SUCCESSFUL: {
      const callback = [];
      if (action.payload.sent) {
        callback.push(
          Cmd.run(COMMANDS.fetchPreviewSendDateCmd, {
            args: [state.key],
            successActionCreator: ACTIONS.fetchPreviewSendDateSuccessful,
            failActionCreator: (error) => ({
              type: ACTION_TYPES.SHOW_ERROR,
              payload: { error: `[ERROR] fetching preview send date: ${error}` },
            }),
          })
        );
      }

      return loop(
        {
          ...state,
          valid: action.payload.valid,
          errors: action.payload.errors,
        },
        Cmd.list(callback)
      );
    }

    case ACTION_TYPES.FETCH_PREVIEW_SEND_DATE_SUCCESFUL: {
      return loop(
        {
          ...state,
          errors: action.payload.errors,
        },
        Cmd.action(
          action.payload.lessThanTwo ? MAILS_ACTIONS.startDeltaSync() : MAILS_ACTIONS.checkMails()
        )
      );
    }

    case ACTION_TYPES.SEND_PREVIEW: {
      return loop(
        {
          ...state,
          sending: {
            state: 'starting',
            progress: 0,
          },
        },
        Cmd.run(COMMANDS.sendPreviewCmd, {
          args: [state.key],
          successActionCreator: ACTIONS.startPolling,
          failActionCreator: ACTIONS.fetchJobIdFailed,
        })
      );
    }

    case ACTION_TYPES.FETCH_JOB_ID_FAILED: {
      console.error('[ERROR] sending:', action.payload);
      return loop(
        {
          ...state,
          errors: action.payload.body.errors,
          sending: {
            state: 'failed',
            progress: 0,
          },
        },
        Cmd.action(ACTIONS.validate())
      );
    }

    case ACTION_TYPES.START_POLLING: {
      return loop(
        {
          ...state,
        },
        Cmd.run(COMMANDS.pollingCmd, {
          args: [action.payload.id, Cmd.dispatch],
          successActionCreator: ACTIONS.pollingStarted,
          failActionCreator: (error) => ({
            type: ACTION_TYPES.SHOW_ERROR,
            payload: { error: `[ERROR] polling job: ${error}` },
          }),
        })
      );
    }

    case ACTION_TYPES.POLLING_STARTED: {
      return {
        ...state,
        intervalId: action.payload,
      };
    }

    case ACTION_TYPES.END_POLLING: {
      clearInterval(state.intervalId);
      const callback = [
        Cmd.action(ACTIONS.validate(false)),
        Cmd.action(ACTIONS.setSendingStatus(action.payload)),
      ];
      if (action.payload.state === 'completed') {
        callback.push(
          Cmd.run(COMMANDS.fetchPreviewSendDateCmd, {
            args: [state.key],
            successActionCreator: ACTIONS.fetchPreviewSendDateSuccessful,
            failActionCreator: (error) => ({
              type: ACTION_TYPES.SHOW_ERROR,
              payload: { error: `[ERROR] fetching preview send date: ${error}` },
            }),
          })
        );
      }

      return loop(
        {
          ...state,
          intervalId: null,
        },
        Cmd.list(callback)
      );
    }

    case ACTION_TYPES.SET_SENDING_STATUS: {
      return {
        ...state,
        sending: action.payload,
      };
    }

    case ACTION_TYPES.START_DEBOUNCE_FETCH_PREVIEW: {
      clearTimeout(state.debounceId);
      return loop(
        {
          ...state,
          debounceId: null,
          loading: true,
        },
        Cmd.run(COMMANDS.DebouncePreviewCmd, {
          args: [state.key, Cmd.getState, Cmd.dispatch],
          successActionCreator: ACTIONS.debounceStarted,
          failActionCreator: (error) => ({
            type: ACTION_TYPES.SHOW_ERROR,
            payload: { error: `[ERROR] debounce fetch: ${error}` },
          }),
        })
      );
    }

    case ACTION_TYPES.DEBOUNCE_STARTED: {
      return {
        ...state,
        debounceId: action.payload,
      };
    }

    case ACTION_TYPES.END_DEBOUNCE_FETCH_PREVIEW: {
      clearTimeout(state.debounceId);
      return loop(
        {
          ...state,
          debounceId: null,
          loading: false,
        },
        Cmd.action(ACTIONS.fetchPreviewSuccessful(action.payload))
      );
    }

    case ACTION_TYPES.SET_TYPE: {
      return loop(
        {
          ...state,
        },
        Cmd.run(COMMANDS.setTypeCmd, {
          args: [state.key],
          successActionCreator: ACTIONS.setTypeSuccessful,
          failActionCreator: (error) => ({
            type: ACTION_TYPES.SHOW_ERROR,
            payload: { error: `[ERROR] getting newsletter type: ${error}` },
          }),
        })
      );
    }

    case ACTION_TYPES.SET_TYPE_SUCCESSFUL: {
      return {
        ...state,
        type: action.payload.type,
      };
    }

    case ACTION_TYPES.SEND_TEST_PREVIEW: {
      return loop(
        {
          ...state,
        },
        Cmd.run(COMMANDS.sendTestPreviewCmd, {
          args: [state.key, action.payload, Cmd.getState],
          successActionCreator: () => ({ type: ACTION_TYPES.SEND_TEST_PREVIEW_SUCCESSFUL }),
          failActionCreator: (error) => ({
            type: ACTION_TYPES.SEND_TEST_PREVIEW_FAILED,
            payload: { error },
          }),
        })
      );
    }

    case ACTION_TYPES.SEND_TEST_PREVIEW_SUCCESSFUL: {
      return {
        ...state,
        testPreviewSuccess: true,
      };
    }

    case ACTION_TYPES.SEND_TEST_PREVIEW_FAILED: {
      return {
        ...state,
        testPreviewSuccess: false,
      };
    }

    default:
      return state;
  }
};
