import * as ACTION_TYPES from '../constants/actionTypes';

export const fetchUser = (newUser) => ({
  type: ACTION_TYPES.FETCH_USER,
  payload: newUser,
});

export const fetchUserSuccessful = (user) => ({
  type: ACTION_TYPES.FETCH_USER_SUCCESSFUL,
  payload: user,
});

export const logOut = (user) => ({
  type: ACTION_TYPES.LOG_OUT,
  payload: user,
});
