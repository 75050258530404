import { thirdGradeOldStructure } from '@kathondvla/curricula-selector';
import { sriClient, contentApi, newsletterApi } from '../api/apiConfig';
import {
  REGIONS,
  REFERENCE_FRAME_THEMES,
  REFERENCE_FRAME_SUBJECTS,
  NEWSLETTER_TYPE,
} from '../static/constants';
import { isIncluded } from '../helpers/viewOptionsHelper';

export const getMainstructuresOutype = () =>
  sriClient.getAll('/namedsets', { tags: 'mainstructure_outype_ext_comm' });
export const getFunctions = () => sriClient.getAll('/namedsets', { tags: 'doelgroepen' });
export const getCoverage = () => REGIONS;
export const getThemes = async () => {
  const themes = await sriClient.getAll('/content', {
    root: '/content/dfa63c53-a63c-4f61-90bd-6ab13644c538',
  });

  const themeMap = new Map();

  themes.forEach((theme) => {
    themeMap[theme.$$meta.permalink] = theme;
  });

  return REFERENCE_FRAME_THEMES.map((themeHref) => {
    const themeContent = themeMap[themeHref];

    return {
      ...themeContent,
      href: themeContent.$$meta.permalink,
    };
  });
};

export const getNonCurriculumThemes = async () => {
  const sectionCurrVakkenEnLeerplannenOldStructureForThirdGrade = await sriClient.getAll(
    '/content',
    {
      root: REFERENCE_FRAME_SUBJECTS.sectionCurrVakkenEnLeerplannenOldStructureForThirdGrade,
    }
  );

  const subjects = [];

  const listOfHrefs = [
    ...thirdGradeOldStructure.studyFieldHrefs,
    ...thirdGradeOldStructure.subjectHrefs,
  ];

  listOfHrefs.forEach((href) => {
    const subject = sectionCurrVakkenEnLeerplannenOldStructureForThirdGrade.find(
      (s) => s.$$meta.permalink === href
    );

    subjects.push({
      name: subject.title,
      href: subject.$$meta.permalink,
      key: subject.key,
      type: subject.$$meta.type,
    });
  });

  return subjects;
};

export const getCurriculumThemes = async () => {
  const sectionCurrVakkenEnLeerplannenModerniseringSo = await sriClient.getAll('/content', {
    root: REFERENCE_FRAME_SUBJECTS.sectionCurrVakkenEnLeerplannenModerniseringSo,
  });

  const subjects = [];

  sectionCurrVakkenEnLeerplannenModerniseringSo
    .filter((subject) => subject.type === 'CURRICULUM_THEME')
    .forEach((subject) => {
      subjects.push({
        name: subject.title,
        href: subject.$$meta.permalink,
        key: subject.key,
        type: subject.type,
      });
    });

  return subjects;
};

export const loadViewOptionsCmd = async () => {
  try {
    const mainstructuresOuTypeCombinations = await getMainstructuresOutype();
    const positions = await getFunctions();
    const coverage = getCoverage();
    const themes = await getThemes();
    const curriculumThemes = await getCurriculumThemes();
    const nonCurriculumThemes = await getNonCurriculumThemes();

    return {
      options: {
        mainstructuresOuTypeCombinations: mainstructuresOuTypeCombinations
          .filter((e) => e)
          .sort((a, b) => a.name.localeCompare(b.name)),
        positions: positions
          .filter((e) => e)
          .sort((a, b) => {
            if (a.name === 'Andere') {
              return 1;
            }
            if (b.name === 'Andere') {
              return -1;
            }
            return a.name.localeCompare(b.name);
          }),
        coverage,
        themes,
        curriculumThemes: curriculumThemes.sort((a, b) => a.name.localeCompare(b.name)),
        nonCurriculumThemes: nonCurriculumThemes.sort((a, b) => a.name.localeCompare(b.name)),
      },
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const loadPersonalViewOptionsCmd = async (viewOptions, getState) => {
  try {
    const state = getState();
    const root = state.preview.key;
    const personKey = state.user.$$meta.permalink;
    const defaultOptions = {
      mainstructuresOuTypeCombinations: viewOptions.mainstructuresOuTypeCombinations.map((e) => {
        e.checked = true;
        return e;
      }),
      positions: viewOptions.positions.map((e) => {
        e.checked = true;
        return e;
      }),
      coverage: viewOptions.coverage.map((e) => {
        e.checked = true;
        return e;
      }),
      themes: viewOptions.themes.map((e) => {
        e.checked = state.preview.type === NEWSLETTER_TYPE.generalNewsletter;
        return e;
      }),
      curriculumThemes: viewOptions.curriculumThemes.map((e) => {
        e.checked = state.preview.type === NEWSLETTER_TYPE.subjectNewsletter;
        return e;
      }),
      nonCurriculumThemes: viewOptions.nonCurriculumThemes.map((e) => {
        e.checked = state.preview.type === NEWSLETTER_TYPE.subjectNewsletter;
        return e;
      }),
    };
    const contentItem = await contentApi.get(`/content/${root}`);
    const allPreferences = await newsletterApi.getAll('/newsletter/preferences', {
      person: personKey,
    });

    if (allPreferences && contentItem) {
      const newsletterType = contentItem ? contentItem.newsletterType.href : null;
      const preferences = allPreferences.find((e) => e.newsletterType.href === newsletterType);

      if (preferences) {
        const newViewOptions = Object.keys(viewOptions).reduce((acc, key) => {
          /* In preferences, both curriculumThemes and nonCurriculumThemes are together in themes property */
          const preferencesGroup =
            (key === 'nonCurriculumThemes' || key === 'curriculumThemes') &&
            state.preview.type === NEWSLETTER_TYPE.subjectNewsletter
              ? preferences.themes
              : preferences[key];

          acc[key] = viewOptions[key].map((viewOption) => {
            viewOption.checked = preferencesGroup
              ? isIncluded(
                  preferencesGroup.map((e) => e.href),
                  viewOption
                )
              : false;
            return viewOption;
          });

          return acc;
        }, {});

        return {
          options: newViewOptions,
        };
      }

      return {
        options: defaultOptions,
      };
    }

    return {
      options: defaultOptions,
    };
  } catch (error) {
    throw new Error(error);
  }
};
