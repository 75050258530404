/**
 * This defines your own module for this application. If you have other dependencies
 * on other modules, add them here.
 *
 * Remark that we get the modulename from the window.vskoConfiguration object (see
 * config/constants.js), which makes it easier to update the modulename in one place !
 *
 * This configuration will be added to the application module as a constant also, so it can be
 * used 'the angular way' later on in your modules, controllers, directives (both the OAuth module
 * and the RemoteErrorLogger need some info from the 'configuration' ! )
 */

import ngReduxRouter from 'redux-ui-router';
import { settings } from '../config/settings';

require('../../css/framework/bootstrap.css');
require('../../css/custom.scss');
require('../../css/_variables.scss');
require('../../css/main.scss');

const angular = require('angular');

const basicConfig = {
  name: 'api',
  baseUrl: settings.apisAndUrls.api,
};

const legacyConfig = {
  name: 'sriClient',
  baseUrl: settings.apisAndUrls.cachedApi,
  caching: {
    timeout: 400,
  },
};

let modules = [];

// if (settings.logging.sentry.enabled) {
//   const Raven = require('raven-js');
//   const ravenPlugin = require('raven-js/plugins/angular');
//   Raven
//     .config(settings.logging.sentry.url)
//     .addPlugin(ravenPlugin, angular)
//     .setEnvironment(settings.environment)
//     .setRelease(settings.release)
//     .install();

//   modules.push(ravenPlugin.moduleName);
// }

// Node Modules
require('angular-sanitize');
require('angular-google-analytics');
require('angular-loading-bar');
require('@uirouter/angularjs');
require('angular-ui-bootstrap');
require('angular-cookies');
require('../services');
require('../module/filters');
require('ng-meta');
require('@kathondvla/curriculum-manager');
// eslint-disable-next-line no-unused-expressions
require('vsko-angular-oauth').client;
require('moment');
require('angular-moment');
require('ui-select');

// Redux
require('ng-redux');

// Custom Modules

modules = [
  ...modules,
  'ng',
  'angular-loading-bar',
  'ui.router',
  'ui.bootstrap',
  'ngCookies',
  'angular-google-analytics',
  'ng-sri-client',
  'VskoOAuth',
  'services',
  'filters',
  'ngMeta',
  'angularMoment',
  'ui.select',
  require('@kathondvla/sri-client/ng-sri-client')([basicConfig, legacyConfig]),
  'ngRedux',
  ngReduxRouter,
];

// create the module
const app = angular.module('VskoApplication', modules);

app.service('$notification', [
  () => {
    const that = {};

    that.error = (message) => {
      console.error(message);
    };

    that.warning = (message) => {
      console.warn(message);
    };

    that.success = (message) => {
      console.log(message);
    };

    that.info = (message) => {
      console.log(message);
    };

    return that;
  },
]);

console.log(`### Software version: ${settings.release} ###`);

export default app;
