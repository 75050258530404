export const isEmpty = (obj = {}) => {
  // return Object.keys(obj).reduce((acc, key) => acc && (obj[key] ? false : true), true);
  return Object.keys(obj).reduce(
    (acc, key) =>
      acc && !!(obj[key] === undefined || (Array.isArray(obj[key]) && !obj[key].length)),
    true
  );
};

export const isIncluded = (arr, item) => {
  if (!item || !arr) {
    return false;
  }
  return arr.includes(item.href) || (item.$$meta && arr.includes(item.$$meta.permalink));
};

export const mapSelected = (viewOptions) =>
  Object.keys(viewOptions).reduce((acc, key) => {
    acc[key] = viewOptions[key].filter((e) => e.checked).map((e) => e.href || e.$$meta.permalink);
    return acc;
  }, {});

export const lessThanHours = (date1, date2, difference) => {
  const date = new Date(date1).getTime();
  const now = new Date(date2).getTime();
  const diff = Math.abs(3600 * difference * 1000);

  return now - date < diff;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};
