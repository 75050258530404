/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as COMMANDS from '../commands/viewOptionsCommands';
import * as ACTIONS from '../actions/viewOptionsActions';
import * as PREVIEW_ACTIONS from '../actions/previewActions';
import * as URL_ACTIONS from '../actions/urlActions';
import { isEmpty, isIncluded } from '../helpers/viewOptionsHelper';

const initialState = {
  coverage: [],
  positions: [],
  mainstructuresOuTypeCombinations: [],
  themes: [],
  curriculumThemes: [],
  nonCurriculumThemes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOAD_VIEW_OPTIONS: {
      return loop(
        {
          ...state,
        },
        Cmd.run(COMMANDS.loadViewOptionsCmd, {
          args: [],
          successActionCreator: ACTIONS.loadViewOptionsSuccessful,
          failActionCreator: (error) => ({
            type: ACTION_TYPES.SHOW_ERROR,
            payload: { error: `[ERROR] loading view options: ${error}` },
          }),
        })
      );
    }

    case ACTION_TYPES.LOAD_VIEW_OPTIONS_SUCCESSFUL: {
      return loop(
        {
          ...state,
          ...action.payload.options,
        },
        Cmd.list([Cmd.action(URL_ACTIONS.getUrlParams({}))])
      );
    }

    case ACTION_TYPES.SET_COVERAGE: {
      const { item } = action.payload;
      const newState = {
        ...state,
        coverage: state.coverage.map((region) => {
          if (region.href === item.href) {
            return {
              ...region,
              checked: item.checked,
            };
          }

          return region;
        }),
      };

      return loop(
        newState,
        Cmd.list([
          Cmd.action(URL_ACTIONS.setUrlParams(newState)),
          Cmd.action(PREVIEW_ACTIONS.setPreviewUrl(newState)),
        ])
      );
    }

    case ACTION_TYPES.SET_POSITION: {
      const { item } = action.payload;
      const newState = {
        ...state,
        positions: state.positions.map((position) => {
          if (position.$$meta.permalink === item.$$meta.permalink) {
            return {
              ...position,
              checked: item.checked,
            };
          }

          return position;
        }),
      };

      return loop(
        newState,
        Cmd.list([
          Cmd.action(URL_ACTIONS.setUrlParams(newState)),
          Cmd.action(PREVIEW_ACTIONS.setPreviewUrl(newState)),
        ])
      );
    }

    case ACTION_TYPES.SET_MAINSTRUCTURES_OUTYPE_COMBINATION: {
      const { item } = action.payload;
      const newState = {
        ...state,
        mainstructuresOuTypeCombinations: state.mainstructuresOuTypeCombinations.map(
          (mainstructuresOuType) => {
            if (mainstructuresOuType.$$meta.permalink === item.$$meta.permalink) {
              return {
                ...mainstructuresOuType,
                checked: item.checked,
              };
            }

            return mainstructuresOuType;
          }
        ),
      };

      return loop(
        newState,
        Cmd.list([
          Cmd.action(URL_ACTIONS.setUrlParams(newState)),
          Cmd.action(PREVIEW_ACTIONS.setPreviewUrl(newState)),
        ])
      );
    }

    case ACTION_TYPES.SET_THEME: {
      const { item } = action.payload;
      const newState = {
        ...state,
        themes: state.themes.map((theme) => {
          if (theme.href === item.href) {
            return {
              ...theme,
              checked: item.checked,
            };
          }

          return theme;
        }),
      };

      return loop(
        newState,
        Cmd.list([
          Cmd.action(URL_ACTIONS.setUrlParams(newState)),
          Cmd.action(PREVIEW_ACTIONS.setPreviewUrl(newState)),
        ])
      );
    }

    case ACTION_TYPES.SET_CURRICULUM_THEME: {
      const { item } = action.payload;
      const newState = {
        ...state,
        curriculumThemes: state.curriculumThemes.map((theme) => {
          if (theme.href === item.href) {
            return {
              ...theme,
              checked: item.checked,
            };
          }

          return theme;
        }),
      };

      return loop(
        newState,
        Cmd.list([
          Cmd.action(URL_ACTIONS.setUrlParams(newState)),
          Cmd.action(PREVIEW_ACTIONS.setPreviewUrl(newState)),
        ])
      );
    }

    case ACTION_TYPES.SET_NON_CURRICULUM_THEME: {
      const { item } = action.payload;
      const newState = {
        ...state,
        nonCurriculumThemes: state.nonCurriculumThemes.map((theme) => {
          if (theme.href === item.href) {
            return {
              ...theme,
              checked: item.checked,
            };
          }

          return theme;
        }),
      };

      return loop(
        newState,
        Cmd.list([
          Cmd.action(URL_ACTIONS.setUrlParams(newState)),
          Cmd.action(PREVIEW_ACTIONS.setPreviewUrl(newState)),
        ])
      );
    }

    case ACTION_TYPES.SET_SUBJECTS: {
      const data = action.payload.item;
      // reset all checked flags
      const newState = {
        ...state,
        curriculumThemes: state.curriculumThemes.map((theme) => ({ ...theme, checked: false })),
        nonCurriculumThemes: state.nonCurriculumThemes.map((theme) => ({
          ...theme,
          checked: false,
        })),
      };

      const curriculumThemesHrefSet = new Set(state.curriculumThemes.map((theme) => theme.href));

      data.forEach((subject) => {
        if (curriculumThemesHrefSet.has(subject.href)) {
          const ct = newState.curriculumThemes.find((z) => z.href === subject.href);
          ct.checked = true;
        } else {
          const nct = newState.nonCurriculumThemes.find((z) => z.href === subject.href);
          nct.checked = true;
        }
      });

      return loop(
        newState,
        Cmd.list([
          Cmd.action(URL_ACTIONS.setUrlParams(newState)),
          Cmd.action(PREVIEW_ACTIONS.setPreviewUrl(newState)),
        ])
      );
    }

    case ACTION_TYPES.SELECT_ALL: {
      const type = action.payload;
      const newState = {
        ...state,
        [type]: state[type].map((item) => ({ ...item, checked: true })),
      };

      return loop(
        newState,
        Cmd.list([
          Cmd.action(URL_ACTIONS.setUrlParams(newState)),
          Cmd.action(PREVIEW_ACTIONS.setPreviewUrl(newState)),
        ])
      );
    }

    case ACTION_TYPES.UNSELECT_ALL: {
      const type = action.payload;
      const newState = {
        ...state,
        [type]: state[type].map((item) => ({ ...item, checked: false })),
      };

      return loop(
        newState,
        Cmd.list([
          Cmd.action(URL_ACTIONS.setUrlParams(newState)),
          Cmd.action(PREVIEW_ACTIONS.setPreviewUrl(newState)),
        ])
      );
    }

    case ACTION_TYPES.UPDATE_VIEW_OPTIONS_FROM_URL: {
      const { options } = action.payload;
      const allEmpty = isEmpty(options);
      const newState = Object.keys(state).reduce((acc, key) => {
        acc[key] = state[key].map((e) => {
          e.checked = !!(allEmpty || isIncluded(options[key], e));
          // e.checked = options[key] && (options[key].includes(e.href) ||
          //   (e.$$meta && options[key].includes(e.$$meta.permalink))) ? true : false;
          return e;
        });

        return acc;
      }, {});

      return newState;
    }

    case ACTION_TYPES.LOAD_PERSONAL_VIEW_OPTIONS: {
      return loop(
        {
          ...state,
        },
        Cmd.run(COMMANDS.loadPersonalViewOptionsCmd, {
          args: [state, Cmd.getState],
          successActionCreator: ACTIONS.loadPersonalViewOptionsSuccessful,
          failActionCreator: (error) => ({
            type: ACTION_TYPES.SHOW_ERROR,
            payload: {
              error: `[ERROR] loading personal view options: ${error}`,
            },
          }),
        })
      );
    }

    case ACTION_TYPES.LOAD_PERSONAL_VIEW_OPTIONS_SUCCESSFUL: {
      const newState = {
        ...state,
        ...action.payload.options,
      };

      return loop(
        newState,
        Cmd.list([
          Cmd.action(URL_ACTIONS.setUrlParams(newState)),
          Cmd.action(PREVIEW_ACTIONS.setPreviewUrl(action.payload.options)),
        ])
      );
    }

    default:
      return state;
  }
};
