import * as ACTION_TYPES from '../constants/actionTypes';

export const loadViewOptions = (results) => ({
  type: ACTION_TYPES.LOAD_VIEW_OPTIONS,
  payload: results,
});

export const loadViewOptionsSuccessful = (result) => ({
  type: ACTION_TYPES.LOAD_VIEW_OPTIONS_SUCCESSFUL,
  payload: {
    options: result.options,
  },
});

export const setCoverage = (result) => ({
  type: ACTION_TYPES.SET_COVERAGE,
  payload: {
    item: result,
  },
});

export const setPosition = (result) => ({
  type: ACTION_TYPES.SET_POSITION,
  payload: {
    item: result,
  },
});

export const setMainstructuresOuType = (result) => ({
  type: ACTION_TYPES.SET_MAINSTRUCTURES_OUTYPE_COMBINATION,
  payload: {
    item: result,
  },
});

export const setTheme = (result) => ({
  type: ACTION_TYPES.SET_THEME,
  payload: {
    item: result,
  },
});

export const setCurriculumTheme = (result) => ({
  type: ACTION_TYPES.SET_CURRICULUM_THEME,
  payload: {
    item: result,
  },
});

export const setNonCurriculumTheme = (result) => ({
  type: ACTION_TYPES.SET_NON_CURRICULUM_THEME,
  payload: {
    item: result,
  },
});

export const setSubjects = (result) => ({
  type: ACTION_TYPES.SET_SUBJECTS,
  payload: {
    item: result,
  },
});

export const updateViewOptionsFromUrl = (result) => ({
  type: ACTION_TYPES.UPDATE_VIEW_OPTIONS_FROM_URL,
  payload: {
    options: result,
  },
});

export const loadPersonalViewOptions = (results) => ({
  type: ACTION_TYPES.LOAD_PERSONAL_VIEW_OPTIONS,
  payload: results,
});

export const loadPersonalViewOptionsSuccessful = (result) => ({
  type: ACTION_TYPES.LOAD_PERSONAL_VIEW_OPTIONS_SUCCESSFUL,
  payload: {
    options: result.options,
  },
});

export const selectAll = (type) => ({
  type: ACTION_TYPES.SELECT_ALL,
  payload: type,
});

export const unselectAll = (type) => ({
  type: ACTION_TYPES.UNSELECT_ALL,
  payload: type,
});
