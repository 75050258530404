import React from 'react';
import Button from '../../../../reactComponents/Button/Button';

const CurriculumSelectorButton = ({ label, icon, onClick }) => (
  <div className="">
    <span className="input-group">
      <span className="">
        <Button icon={icon} onClick={onClick} className={'curriculumSelectorButton'}>
          {label}
        </Button>
      </span>
    </span>
  </div>
);

export default CurriculumSelectorButton;
