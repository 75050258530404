export const INIT = 'init';

export const LOAD_VIEW_OPTIONS = 'load_view_options';
export const LOAD_VIEW_OPTIONS_SUCCESSFUL = 'load_view_options_successful';
export const SET_COVERAGE = 'set_coverage';
export const SET_POSITION = 'set_position';
export const SET_MAINSTRUCTURES_OUTYPE_COMBINATION = 'set_mainstructures_outype_combination';
export const SET_THEME = 'set_theme';
export const SET_CURRICULUM_THEME = 'set_curriculum_theme';
export const SET_NON_CURRICULUM_THEME = 'set_non_curriculum_theme';
export const SET_SUBJECTS = 'set_subjects';
export const UPDATE_VIEW_OPTIONS_FROM_URL = 'update_view_options_from_url';
export const LOAD_PERSONAL_VIEW_OPTIONS = 'load_personal_view_options';
export const LOAD_PERSONAL_VIEW_OPTIONS_SUCCESSFUL = 'load_personal_view_options_successful';
export const SELECT_ALL = 'select_all';
export const UNSELECT_ALL = 'unselect_all';

export const GET_URL_PARAMS = 'get_url_params';
export const GET_URL_PARAMS_SUCCESSFUL = 'get_url_params_successful';
export const SET_URL_PARAMS = 'set_url_params';
export const SET_URL_PARAM = 'set_url_param';

export const SHOW_ERROR = 'show_error';
export const FETCH_PREVIEW_SUCCESSFUL = 'fetch_preview_successful';
export const SET_PREVIEW_URL = 'set_preview_url';
export const VALIDATE = 'validate';
export const VALIDATE_SUCCESSFUL = 'validate_successful';
export const SEND_PREVIEW = 'send_preview';
export const START_POLLING = 'start_polling';
export const END_POLLING = 'end_polling';
export const POLLING_STARTED = 'polling_started';
export const SET_SENDING_STATUS = 'set_sending_status';
export const START_DEBOUNCE_FETCH_PREVIEW = 'start_debounce_fetch_preview';
export const DEBOUNCE_STARTED = 'debounce_started';
export const END_DEBOUNCE_FETCH_PREVIEW = 'end_debounce_fetch_preview';
export const FETCH_PREVIEW_SEND_DATE_SUCCESFUL = 'fetch_preview_send_date_succesful';
export const FETCH_JOB_ID_FAILED = 'fetch_job_id_failed';
export const SET_TYPE = 'set_type';
export const SET_TYPE_SUCCESSFUL = 'set_type_successful';
export const SEND_TEST_PREVIEW = 'send_test_preview';
export const SEND_TEST_PREVIEW_SUCCESSFUL = 'send_test_preview_successful';
export const SEND_TEST_PREVIEW_FAILED = 'send_test_preview_failed';

export const FETCH_USER = 'fecth_user';
export const FETCH_USER_SUCCESSFUL = 'fetch_user_successful';
export const LOG_OUT = 'log_out';

export const IS_ALLOWED_TO_SEND = 'is_allowed_to_send';
export const IS_ALLOWED_TO_SEND_SUCCESS = 'is_allowed_to_send_success';

// export const FETCH_MAIL_JOBS = 'fetch_mail_jobs';
// export const FETCH_MAIL_JOBS_SUCCESSFUL = 'fetch_mail_jobs_successful';
export const CHECK_MAILS = 'check_mails';
export const CHECK_MAILS_SUCCESSFUL = 'check_mails_successful';
export const START_DELTA_SYNC = 'start_delta_sync';
export const DELTA_SYNC_STARTED = 'delta_sync_started';
export const END_DELTA_SYNC = 'end_delta_sync';
