// export const offsetFromTop = 85;

// export const elmYPosition = (eID) => {
//   const elm = document.getElementById(eID);
//   let position;
//   if (!elm) {
//     console.warn(`${eID} is not found`);
//   } else {
//     position = elm.offsetTop;
//     let node = elm;
//     while (node.offsetParent && node.offsetParent !== document.body) {
//       node = node.offsetParent;
//       position += node.offsetTop;
//     }
//   }
//   return position;
// };

// export const elmYEndPosition = (eID) => {
//   const elm = document.getElementById(eID);
//   let position;
//   if (!elm) {
//     console.warn(`${eID} is not found`);
//   } else {
//     position = elm.offsetTop + elm.offsetHeight;
//     let node = elm;
//     while (node.offsetParent && node.offsetParent !== document.body) {
//       node = node.offsetParent;
//       position += node.offsetTop;
//     }
//   }
//   return position;
// };

// export const scrollTo = (key) => {
//   window.scrollTo(0, this.elmYPosition(key) - this.offsetFromTop + 1);
// };

// export const array_move = (arr, oldIndex, newIndex) => {
//   if (newIndex >= arr.length) {
//     let k = newIndex - arr.length + 1;
//     while (k > 0) {
//       k -= 1;
//       arr.push(undefined);
//     }
//   }
//   arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
//   return arr; // for testing
// };

// export const arrayToMap = (array) => {
//   return array.reduce((obj, item) => {
//     obj[item.$$meta.permalink] = item;
//     return obj;
//   }, {});
// };

// export const flattenTreeToMap = (tree, onlyVisible = true) => {
//   const flatMap = new Map();
//   function addItems(items) {
//     items.forEach((item) => {
//       flatMap.set(item.href, item);
//       if (item.children) {
//         addItems(item.children.filter(child => !onlyVisible || child.visible));
//       }
//     });
//   }

//   if (tree && (!onlyVisible || tree.visible)) {
//     flatMap.set(tree.href, tree);
//     if (tree.children) {
//       addItems(tree.children.filter(child => !onlyVisible || child.visible));
//     }
//   }
//   return flatMap;
// };

// export const flattenTree = (tree, onlyVisible = true) => {
//   const flatMap = [];
//   function addItems(items) {
//     items.forEach((item) => {
//       flatMap.push(item);
//       if (item.children) {
//         addItems(item.children.filter(child => !onlyVisible || child.visible));
//       }
//     });
//   }

//   if (tree && (!onlyVisible || tree.visible)) {
//     flatMap.push(tree);
//     if (tree.children) {
//       addItems(tree.children.filter(child => !onlyVisible || child.visible));
//     }
//   }
//   return flatMap;
// };

// export const haveCommonElements = (array1, array2) => {
//   const intersection = array1.filter(x => array2.includes(x));

//   return intersection.length > 0;
// };

// export const getInitials = (user) => {
//   let initials = {
//     first: '',
//     second: ''
//   };

//   if (user) {
//     if (user.firstName) initials.first = user.firstName.toUpperCase().charAt(0);
//     if (user.lastName) initials.second = user.lastName.toUpperCase().charAt(0);
//   }

//   return initials.first + initials.second;
// }

export default {
  test: () => console.log('working'),
};
