import { securityClient } from '../api/apiConfig';
import { settings } from '../../config/settings';

export const isAllowedToSend = async (key) => {
  const securityGroups = await securityClient.getRaw('/security/query/resources/raw', {
    person: `/persons/${key}`,
    component: settings.newsletterSecurity.componentHref,
    ability: settings.newsletterSecurity.ability,
  });

  return securityGroups.includes(settings.newsletterSecurity.resource);
};
