import * as VIEW_OPTIONS_ACTIONS from '../../../reduxLoop/actions/viewOptionsActions';

require('./npMainstructuresOutype.scss');

class NpMainstructuresOutype {
  constructor($ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const selectedMainstructuresOuTypeCombinations =
        state.viewOptions.mainstructuresOuTypeCombinations.filter((item) => item.checked);
      return {
        mainstructuresOuTypeCombinations: state.viewOptions.mainstructuresOuTypeCombinations,
        selectedMainstructuresOuTypeCombinations,
      };
    })(this);
  }

  async $onDestroy() {
    this.unsubscribe();
  }

  onCheckboxChange(ev, item) {
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.setMainstructuresOuType(item));
  }

  onSelectAll() {
    this.selectedMainstructuresOuTypeCombinations = this.mainstructuresOuTypeCombinations;
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.selectAll('mainstructuresOuTypeCombinations'));
  }

  onClearAll() {
    this.selectedMainstructuresOuTypeCombinations = [];
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.unselectAll('mainstructuresOuTypeCombinations'));
  }
}

export default {
  template: require('./npMainstructuresOutype.html'),
  controllerAs: 'ctrl',
  controller: NpMainstructuresOutype,
};
