import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from '../../../../reactComponents/Checkbox/Checkbox';
import * as VIEW_OPTIONS_ACTIONS from '../../../../reduxLoop/actions/viewOptionsActions';
import arrow from '../../../../../assets/images/icoonRightArrow_34495E.png';

const CurriculumSelectorListOfSubjects = ({ subjects, showMoreOption, type }) => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(8);

  const updateCheckbox = (subject) => {
    if (type === 'curriculumThemes') {
      dispatch(
        VIEW_OPTIONS_ACTIONS.setCurriculumTheme({
          ...subject,
          checked: !subject.checked,
        })
      );
    } else {
      dispatch(
        VIEW_OPTIONS_ACTIONS.setNonCurriculumTheme({
          ...subject,
          checked: !subject.checked,
        })
      );
    }
  };

  const toggleShowMore = () => (limit === 8 ? setLimit(subjects.length) : setLimit(8));

  return (
    <div className="checkbox-list-wrapper">
      <div className="row" style={{ marginLeft: '15px' }}>
        <div className="col-md-12 checkbox-list-items">
          {subjects.slice(0, limit).map((subject) => (
            <Checkbox
              key={subject.key}
              id={subject.href}
              label={subject.name}
              checked={subject.checked}
              onChange={() => updateCheckbox(subject)}
            />
          ))}
        </div>
        {showMoreOption && subjects.length > 8 ? (
          <div className="col-md-12 show-more">
            <a onClick={() => toggleShowMore()}>
              <img
                src={arrow}
                className={`${limit === 8 ? 'icon-arrow-down' : 'icon-arrow-up'}`}
                width={6}
                height={10}
              />
              {limit === 8 ? <span> Toon alle</span> : <span> Laat minder zien</span>}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CurriculumSelectorListOfSubjects;
