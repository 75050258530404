/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as COMMANDS from '../commands/mailsCommands';
import * as ACTIONS from '../actions/mailsActions';

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case ACTION_TYPES.FETCH_MAIL_JOBS: {
    //   return loop({
    //     ...state,
    //     loading: true,
    //   },
    //   Cmd.run(COMMANDS.fetchMailJobsCmd, {
    //     args: [Cmd.getState],
    //     successActionCreator: ACTIONS.fetchMailJobsSuccessful,
    //     failActionCreator: error => console.error('[ERROR] fetching emailJobs:', error),
    //   }));
    // }

    case ACTION_TYPES.CHECK_MAILS: {
      return loop(
        {
          ...state,
          loading: true,
        },
        Cmd.run(COMMANDS.checkMailsCmd, {
          args: [Cmd.getState],
          successActionCreator: ACTIONS.checkMailsSuccessful,
          failActionCreator: (error) => console.error('[ERROR] fetching emailJobs:', error),
        })
      );
    }

    case ACTION_TYPES.CHECK_MAILS_SUCCESSFUL: {
      return {
        ...state,
        mails: action.payload,
      };
    }

    case ACTION_TYPES.START_DELTA_SYNC: {
      return loop(
        {
          ...state,
          loading: true,
        },
        Cmd.run(COMMANDS.deltaSyncMailsCmd, {
          args: [Cmd.getState, Cmd.dispatch],
          successActionCreator: ACTIONS.deltaSyncStarted,
          failActionCreator: (error) => console.error('[ERROR] delta sync mails:', error),
        })
      );
    }

    case ACTION_TYPES.DELTA_SYNC_STARTED: {
      return {
        ...state,
        deltaSyncId: action.payload,
      };
    }

    case ACTION_TYPES.END_DELTA_SYNC: {
      clearInterval(state.deltaSyncId);
      return {
        ...state,
        mails: action.payload,
        deltaSyncId: null,
        loading: false,
      };
    }

    default:
      return state;
  }
};
