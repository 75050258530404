function isSameScope(parentElemScope, componentScope, eventScope) {
  if (componentScope === eventScope) {
    return true;
  }

  if (parentElemScope === componentScope) {
    return false;
  }

  return isSameScope(parentElemScope, componentScope, eventScope.$parent);
}

module.exports = [
  () => {
    return {
      restrict: 'A',
      scope: false,
      link(scope, element, attrs) {
        let events = scope.$eval(attrs.onEvent);
        const componentScope = angular.element(element[0].childNodes[0]).scope();

        if (!Array.isArray(events)) {
          console.error('You are not using onEvent correctly.');
        }

        if (!Array.isArray(events[0])) {
          events = [events];
        }

        events.forEach((event) => {
          const eventName = event[0];
          let eventHandler = event[1];
          const enablePropagation = event[2];

          if (typeof eventHandler === 'string') {
            eventHandler = (e, data) => {
              scope[eventHandler] = data;
            };
          }

          scope.$on(eventName, (e, arg) => {
            if (!enablePropagation) {
              e.stopPropagation();
            }

            if (isSameScope(scope, componentScope, e.targetScope)) {
              if (typeof eventHandler === 'function') {
                const eventHandlerBinded = angular.bind(scope.ctrl, eventHandler); // Binding scope.ctrl to callback function's this
                eventHandlerBinded(e, arg);
              } else {
                console.error(`eventHandler is not a function for event: ${eventName}`);
              }
            }
          });
        });
      },
    };
  },
];
