import React from 'react';

const Button = ({ children, icon, className, disabled, onClick = () => {} }) => {
  const buttonClass = `kov-btn kov-btn--light kov-btn--block kov-btn--of ${className || ''}`;

  return (
    <button type="button" className={buttonClass} disabled={disabled} onClick={onClick}>
      {children && <span className="children">{children}</span>}
      {icon && <span className={`glyphicon ${icon}`}></span>}
    </button>
  );
};

export default Button;
