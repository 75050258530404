import { sriClient } from '../api/apiConfig';
import { MAIL_TYPES } from '../static/constants';
import * as MAILS_ACTIONS from '../actions/mailsActions';

const DELTA_SYNC_TIMER = 60000;
let emailsStatusRunning = false;
let emailStatusProm;
// export const fetchMailJobsCmd = async (getState) => {
//   try{
//     const CacheMailJobs = getState().mails.mailJobs;
//     if(CacheMailJobs) return mailJobs;

//     const root = getState().preview.key;
//     const mailJobs = await sriClient.getAll('/mailer/emailjobs', {externalReferences: `/content/${root}`, expand: 'NONE', limit: 500});
//     const mailJobsList = mailJobs.filter(e => e).map(e => e.href);

//     return mailJobsList;

//   }catch(error){
//     throw new Error(error);
//   }
// };

async function getEmailsStatus(root) {
  try {
    emailsStatusRunning = true;
    const mailsStatus = await sriClient.getRaw('/mailer/emails/status', {
      externalReferences: `/content/${root}`,
    });
    const defaultValues = {
      ERROR: 0,
      SENT: 0,
      QUEUED: 0,
      count: 0, // mailsStatus.results.reduce((a, b) => a.count + b.count, 0)
    };

    const mailsMap = mailsStatus.results.reduce((acc, mailstat) => {
      const type = MAIL_TYPES[mailstat.status];
      if (type) {
        acc[type] += mailstat.count;
      }
      acc.count += mailstat.count;
      return acc;
    }, defaultValues);

    mailsMap.percentage = Math.round((mailsMap.SENT * 100) / mailsMap.count);
    return mailsMap;
  } catch (error) {
    throw new Error(error);
  } finally {
    emailsStatusRunning = false;
  }
}

export const checkMailsCmd = async (getState) => {
  try {
    const root = getState().preview.key;

    if (!emailsStatusRunning) {
      // stops the Fn running more than once at a time;
      emailStatusProm = getEmailsStatus(root);
    }

    return await emailStatusProm;
  } catch (error) {
    throw new Error(error);
  }
};

export const deltaSyncMailsCmd = async (getState, dispatch) => {
  const mails = await checkMailsCmd(getState);
  try {
    const deltaSync = async () => {
      if (mails.QUEUED && mails.QUEUED > 0) {
        return dispatch(MAILS_ACTIONS.checkMailsSuccessful(mails));
      }
      return dispatch(MAILS_ACTIONS.endDeltaSync(mails));
    };
    deltaSync();

    return setInterval(deltaSync, DELTA_SYNC_TIMER);
  } catch (error) {
    dispatch(MAILS_ACTIONS.endDeltaSync(mails));
    throw new Error(error);
  }
};
