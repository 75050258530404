import { curriculumSitesLogic } from '@kathondvla/shared-logic';
import { newsletterClient, newsletterApi, contentApi } from '../api/apiConfig';
import { stringifyParams, getKeyFromPath } from '../helpers/urlHelper';
import { mapSelected, lessThanHours } from '../helpers/viewOptionsHelper';
import { settings } from '../../config/settings';
import * as PREVIEW_ACTIONS from '../actions/previewActions';

export const setPreviewUrl = (options) => {
  const root = getKeyFromPath();
  const params = stringifyParams(options);

  return {
    key: root,
    url: `${settings.apisAndUrls.newsletter}/preview?newsletterRoot=${root}&${params}`,
  };
};

export const validateCmd = async (key) => {
  try {
    const valid = await newsletterClient.getRaw('/validate', { newsletterRoot: key });
    const isValid = !(valid.errors && valid.errors.length > 0);
    const sent = valid.errors && valid.errors.find((e) => e.code === 'newsletter.sent');

    return {
      valid: isValid,
      sent,
      errors: isValid || sent ? null : valid.errors,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const sendPreviewCmd = async (key) =>
  newsletterClient.post(`/sendwithjob?newsletterRoot=${key}`);

export const pollingCmd = async (jobId, dispatch) => {
  try {
    return setInterval(async () => {
      const status = await newsletterClient.getRaw(`/jobs/${jobId}`);
      const sending = {
        message: status.log && status.log.length ? status.log.map((e) => e.message) : null,
        progress: status.progress,
        state: status.state,
      };

      if (status.state === 'active' || status.state === 'waiting') {
        return dispatch(PREVIEW_ACTIONS.setSendingStatus(sending));
      }
      return dispatch(PREVIEW_ACTIONS.endPolling(sending));
    }, 3000);
  } catch (error) {
    throw new Error(error);
  }
};

export const DebouncePreviewCmd = async (key, getState, dispatch) => {
  try {
    return setTimeout(async () => {
      try {
        const { viewOptions } = getState();
        const options = mapSelected(viewOptions);
        const allThemes = [
          ...options.themes,
          ...options.curriculumThemes,
          ...options.nonCurriculumThemes,
        ];
        if (!allThemes.length) {
          return dispatch(
            PREVIEW_ACTIONS.showError({ error: 'Er moet minstens 1 thema geselecteerd zijn.' })
          );
        }
        options.themes = curriculumSitesLogic.encodeThemes(allThemes);
        delete options.curriculumThemes;
        delete options.nonCurriculumThemes;
        const newsletter = await newsletterClient.getRaw('/preview', {
          ...options,
          newsletterRoot: key,
        });

        return dispatch(PREVIEW_ACTIONS.endDebounceFetchPreview(newsletter));
      } catch (error) {
        dispatch(PREVIEW_ACTIONS.showError({ error: error.body.error }));
        throw new Error(error);
      }
    }, 1500);
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchPreviewSendDateCmd = async (key) => {
  try {
    const [newsletter] = await newsletterApi.getList('/newsletter/settings', {
      'newsletter.href': `/content/${key}`,
    });
    const sentDate = new Date(newsletter.sentDate);
    return {
      sendDate: newsletter.sentDate,
      errors: [
        { message: `De nieuwsbrief is verzonden op ${sentDate.toLocaleDateString('nl-BE')}` },
      ],
      lessThanTwo: lessThanHours(sentDate, new Date(), 2),
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const setTypeCmd = async (root) => {
  const contentItem = await contentApi.get(`/content/${root}`);
  return {
    type: contentItem ? contentItem.newsletterType.href : null,
  };
};

export const sendTestPreviewCmd = async (key, email, getState) => {
  const { viewOptions } = getState();
  const options = mapSelected(viewOptions);
  options.themes = curriculumSitesLogic.encodeThemes([
    ...options.themes,
    ...options.curriculumThemes,
    ...options.nonCurriculumThemes,
  ]);
  newsletterClient.post(
    `/sendTest?newsletterRoot=${key}&recipient=${email}&sendForReal=true&themes=${options.themes}&mainstructuresOuTypeCombinations=${options.mainstructuresOuTypeCombinations}&positions=${options.positions}&coverage=${options.coverage}`
  );
};
