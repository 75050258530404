import React from 'react';
import ReactDOM from 'react-dom';
import { CurriculaSelector } from '@kathondvla/curricula-selector';
import { useSelector } from 'react-redux';
import { cachedSriClient, sriClient } from '../../../../reduxLoop/api/apiConfig';
import { settings } from '../../../../config/settings';

const CurriculumSelectorModal = ({ handleSubmit, handleClose, selectedThemes }) => {
  const apiConfig = {
    sriClient,
    sriClientCached: cachedSriClient,
  };

  const userHref = useSelector((state) => state.user.$$meta.permalink);
  const loginUrl = `${settings.oauth.oauthURL}?redirect_uri=${window.location.origin}`;

  return ReactDOM.createPortal(
    <div className="curriculum-selector">
      <CurriculaSelector
        initialSelection={selectedThemes}
        apiConfig={apiConfig}
        userHref={userHref}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        loginUrl={loginUrl}
      />
    </div>,
    document.body
  );
};

export default CurriculumSelectorModal;
