import * as ACTION_TYPES from '../constants/actionTypes';

export const getUrlParams = (results) => ({
  type: ACTION_TYPES.GET_URL_PARAMS,
  payload: results,
});

export const getUrlParamsSuccessful = (result) => ({
  type: ACTION_TYPES.GET_URL_PARAMS_SUCCESSFUL,
  payload: {
    coverage: result.coverage,
    positions: result.positions,
    mainstructuresOuTypeCombinations: result.mainstructuresOuTypeCombinations,
    themes: result.themes,
    curriculumThemes: result.curriculumThemes,
    nonCurriculumThemes: result.nonCurriculumThemes,
  },
});

export const setUrlParams = (result) => ({
  type: ACTION_TYPES.SET_URL_PARAMS,
  payload: {
    coverage: result.coverage,
    positions: result.positions,
    mainstructuresOuTypeCombinations: result.mainstructuresOuTypeCombinations,
    themes: result.themes,
    curriculumThemes: result.curriculumThemes,
    nonCurriculumThemes: result.nonCurriculumThemes,
  },
});

export const setUrlParam = (result) => ({
  type: ACTION_TYPES.SET_URL_PARAM,
  payload: {
    param: result.param,
    values: result.values,
  },
});
