import * as urlHelper from '../helpers/urlHelper';

export const getUrlParamsCmd = () => {
  const params = urlHelper.getParams();
  const paramsMap = urlHelper.parseParams(params);

  return {
    mainstructuresOuTypeCombinations: paramsMap.mainstructuresOuTypeCombinations,
    positions: paramsMap.positions,
    coverage: paramsMap.coverage,
    themes: paramsMap.themes,
    curriculumThemes: paramsMap.curriculumThemes,
    nonCurriculumThemes: paramsMap.nonCurriculumThemes,
  };
};

export const setUrlParamsCmd = (param) => {
  const result = urlHelper.stringifyParams(param);

  const path = window.location.pathname;
  window.history.replaceState({}, 'updated url', `${path}?${result}`);

  return true;
};

export const setUrlCmd = (param) => {
  const result = urlHelper.stringifyUrl(param);

  const path = window.location.pathname;
  window.history.replaceState({}, 'updated url', `${path}?${result}`);

  return true;
};
