import * as VIEW_OPTIONS_ACTIONS from '../../../reduxLoop/actions/viewOptionsActions';

require('./npFunctions.scss');

class NpFunctions {
  constructor($ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const selectedPositions = state.viewOptions.positions.filter((position) => position.checked);
      return {
        positions: state.viewOptions.positions,
        selectedPositions,
      };
    })(this);
  }

  async $onDestroy() {
    this.unsubscribe();
  }

  onCheckboxChange(ev, item) {
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.setPosition(item));
  }

  onSelectAll() {
    this.selectedPositions = this.positions;
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.selectAll('positions'));
  }

  onClearAll() {
    this.selectedPositions = [];
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.unselectAll('positions'));
  }
}

export default {
  template: require('./npFunctions.html'),
  controllerAs: 'ctrl',
  transclude: true,
  controller: NpFunctions,
};
