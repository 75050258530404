export const REGIONS = [
  {
    href: '/dioceses/1',
    tag: 'ant',
    title: 'Antwerpen',
    checked: false,
  },
  {
    href: '/dioceses/2',
    tag: 'm-b',
    title: 'Mechelen-Brussel',
    checked: false,
  },
  {
    href: '/dioceses/3',
    tag: 'lim',
    title: 'Limburg',
    checked: false,
  },
  {
    href: '/dioceses/4',
    tag: 'o-vl',
    title: 'Oost-Vlaanderen',
    checked: false,
  },
  {
    href: '/dioceses/5',
    tag: 'w-vl',
    title: 'West-Vlaanderen',
    checked: false,
  },
];

export const STATES = {
  starting: 'Starten',
  completed: 'Voltooid',
  failed: 'Gefaald',
  delayed: 'Vertraagd',
  active: 'Bezig',
  waiting: 'Wachten',
  paused: 'Gepauzeerd',
  stuck: 'Vastgelopen',
};

// export const MAIL_TYPES = {
//     SENT: ['SENT'],
//     QUEUED: ['QUEUED', 'SCHEDULED', 'DEFERRED'],
//     ERROR: ['REJECTED', 'INVALID', 'SPAM', 'SOFT-BOUNCE', 'HARD-BOUNCE']
// };

export const MAIL_TYPES = {
  SENT: 'SENT',
  QUEUED: 'QUEUED',
  REJECTED: 'ERROR',
  INVALID: 'ERROR',
  SCHEDULED: 'QUEUED',
  SPAM: 'ERROR',
  'SOFT-BOUNCE': 'ERROR',
  'HARD-BOUNCE': 'ERROR',
  DEFERRED: 'QUEUED',
};

export const TEST_MAILS_RESPONSE = [
  {
    href: '/mailer/emails/bffb7a76-eac0-42e4-92fc-e80b702ebc63',
    $$expanded: {
      $$meta: {
        created: '2018-02-12T03:19:27.479Z',
        modified: '2018-02-12T03:19:27.479Z',
        permalink: '/mailer/emails/bffb7a76-eac0-42e4-92fc-e80b702ebc63',
        version: 0,
        type: 'MAILER_EMAIL',
      },
      key: 'bffb7a76-eac0-42e4-92fc-e80b702ebc63',
      job: {
        href: '/mailer/emailjobs/90195a5c-1384-4731-847d-8137583b3865',
      },
      sentDate: '2018-02-12T03:19:27.448Z',
      scheduledDate: null,
      status: 'SENT',
      recipient: {
        emailAddress: 'stefan.vanderstraeten@katholiekonderwijs.vlaanderen',
      },
      opens: null,
      clicks: null,
      smtpEvents: null,
    },
  },
  {
    href: '/mailer/emails/9fded8a3-fb18-4240-85a4-9247432e69fc',
    $$expanded: {
      $$meta: {
        created: '2020-08-28T11:19:55.312Z',
        modified: '2020-08-28T11:19:58.499Z',
        permalink: '/mailer/emails/9fded8a3-fb18-4240-85a4-9247432e69fc',
        version: 1,
        type: 'MAILER_EMAIL',
      },
      key: '9fded8a3-fb18-4240-85a4-9247432e69fc',
      job: {
        href: '/mailer/emailjobs/eea34ab2-eb3d-4d4a-bc5a-936e0f4266aa',
      },
      sentDate: '2020-08-28T11:19:54.000Z',
      scheduledDate: null,
      status: 'SENT',
      recipient: {
        name: 'Keunen',
        person: {
          href: '/persons/cf2dccb1-12b0-4402-e044-d4856467bfb8',
        },
        firstName: 'Annick',
        emailAddress: 'annick.keunen@katholiekonderwijs.vlaanderen',
      },
      opens: null,
      clicks: null,
      smtpEvents: null,
    },
  },
];

export const REFERENCE_FRAME_SUBJECTS = {
  sectionCurrVakkenEnLeerplannenOldStructureForThirdGrade:
    '/content/2b254a9a-9774-4869-81fc-133d95cb8568',
  sectionCurrVakkenEnLeerplannenModerniseringSo: '/content/e7c8b4bc-e77e-48f4-b1e8-11c26965fe9f',
};

export const REFERENCE_FRAME_THEMES = [
  '/content/5e88881b-e731-48e8-84c7-344664e93623',
  '/content/41fa46b1-80f0-409c-890b-d4de55a60ca0',
  '/content/da038442-e86b-4f27-92dd-e7ac63af1558',
  '/content/8f89ed60-c17a-473e-9058-c27e02312e8c',
  '/content/8c92170b-f88c-46d0-85eb-0463b152695a',
  '/content/199bcf8c-e6ad-4249-b21f-6541a4f233a0',
  '/content/75f232df-6315-4213-beb8-20e3d8f5defc',
  '/content/52286c99-13b9-4d58-95da-76770012eeb1',
  '/content/b520c4f2-c409-44a3-87eb-1a93829333bb',
  '/content/3c1f2b96-3896-41be-8919-8aa69ef6346b',
];

export const NEWSLETTER_TYPE = {
  generalNewsletter: '/newsletter/types/f64b33d8-dbd9-49b8-845b-4f3f2a6c2b78',
  subjectNewsletter: '/newsletter/types/974c6b12-2b90-4d8d-a98f-67b2daab0d8b',
};
