import * as VIEW_OPTIONS_ACTIONS from '../../../reduxLoop/actions/viewOptionsActions';

require('./npThemes.scss');

class NpThemes {
  constructor($ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const selectedThemes = state.viewOptions.themes.filter((theme) => theme.checked);
      return {
        themes: state.viewOptions.themes,
        selectedThemes,
      };
    })(this);
  }

  async $onDestroy() {
    this.unsubscribe();
  }

  onCheckboxChange(ev, item) {
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.setTheme(item));
  }

  onSelectAll() {
    this.selectedPositions = this.positions;
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.selectAll('themes'));
  }

  onClearAll() {
    this.selectedPositions = [];
    this.$ngRedux.dispatch(VIEW_OPTIONS_ACTIONS.unselectAll('themes'));
  }
}

export default {
  template: require('./npThemes.html'),
  controllerAs: 'ctrl',
  transclude: true,
  controller: NpThemes,
};
