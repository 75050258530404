import * as ACTION_TYPES from '../constants/actionTypes';

export const fetchPreviewSuccessful = (preview) => ({
  type: ACTION_TYPES.FETCH_PREVIEW_SUCCESSFUL,
  payload: preview,
});

export const setPreviewUrl = (preview) => ({
  type: ACTION_TYPES.SET_PREVIEW_URL,
  payload: preview,
});

export const validate = (errors) => ({
  type: ACTION_TYPES.VALIDATE,
  payload: errors,
});

export const validateSuccessful = (valid) => ({
  type: ACTION_TYPES.VALIDATE_SUCCESSFUL,
  payload: valid,
});

export const sendPreview = (preview) => ({
  type: ACTION_TYPES.SEND_PREVIEW,
  payload: preview,
});

export const startPolling = (jobId) => ({
  type: ACTION_TYPES.START_POLLING,
  payload: jobId,
});

export const pollingStarted = (intervalId) => ({
  type: ACTION_TYPES.POLLING_STARTED,
  payload: intervalId,
});

export const endPolling = (status) => ({
  type: ACTION_TYPES.END_POLLING,
  payload: status,
});

export const setSendingStatus = (status) => ({
  type: ACTION_TYPES.SET_SENDING_STATUS,
  payload: status,
});

export const startDedounceFetchPreview = (result) => ({
  type: ACTION_TYPES.START_DEBOUNCE_FETCH_PREVIEW,
  payload: result,
});

export const debounceStarted = (debounceId) => ({
  type: ACTION_TYPES.DEBOUNCE_STARTED,
  payload: debounceId,
});

export const endDebounceFetchPreview = (preview) => ({
  type: ACTION_TYPES.END_DEBOUNCE_FETCH_PREVIEW,
  payload: preview,
});

export const fetchPreviewSendDateSuccessful = (sendDate) => ({
  type: ACTION_TYPES.FETCH_PREVIEW_SEND_DATE_SUCCESFUL,
  payload: sendDate,
});

export const fetchJobIdFailed = (error) => ({
  type: ACTION_TYPES.FETCH_JOB_ID_FAILED,
  payload: error,
});

export const setType = (type) => ({
  type: ACTION_TYPES.SET_TYPE,
  payload: type,
});

export const setTypeSuccessful = (type) => ({
  type: ACTION_TYPES.SET_TYPE_SUCCESSFUL,
  payload: type,
});

export const showError = (error) => ({
  type: ACTION_TYPES.SHOW_ERROR,
  payload: error,
});

export const sendTestPreview = (mail) => ({
  type: ACTION_TYPES.SEND_TEST_PREVIEW,
  payload: mail,
});
