import { curriculumSitesLogic } from '@kathondvla/shared-logic';

export const parseParams = (params) => {
  const parsedParams = params.reduce((acc, item) => {
    const [name, values] = decodeURIComponent(item).split('=');
    if (name && values) {
      if (name === 'themes' || name === 'curriculumThemes' || name === 'nonCurriculumThemes') {
        acc[name] = curriculumSitesLogic.decodeThemes(values);
      } else {
        acc[name] = values.split(',');
      }
    }
    return acc;
  }, {});
  return {
    ...parsedParams,
  };
};

export const getParams = () => {
  const params = window.location.search;
  return params.replace('?', '').split('&');
};

export const stringifyUrl = (params) => {
  const result = Object.keys(params).reduce((acc, item) => {
    const values = params[item];
    if (!values || values.length === 0) {
      return acc;
    }

    const encodedValues =
      item === 'themes' || item === 'curriculumThemes' || item === 'nonCurriculumThemes'
        ? encodeURIComponent(curriculumSitesLogic.encodeThemes(values))
        : encodeURIComponent(values.join(','));

    return `${acc}${encodeURIComponent(item)}=${encodedValues}&`;
  }, '');

  return result;
};

export const stringifyParams = (param) => {
  const params = getParams();
  const paramsMap = parseParams(params);

  if (param) {
    paramsMap[param.param] = param.values;
  }

  const result = stringifyUrl(paramsMap);

  return result;
};

export const getKeyFromPath = () =>
  window.location.pathname.replace('preview', '').replace(/\//g, '');
