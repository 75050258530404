/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as USER_ACTIONS from '../actions/userActions';
import * as PREVIEW_ACTIONS from '../actions/previewActions';

const initialState = {
  api: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.INIT: {
      // if (state.user && !action.payload) {
      //   return state;
      // }
      return loop(
        state,
        Cmd.list([
          Cmd.action(USER_ACTIONS.fetchUser()),
          Cmd.action(PREVIEW_ACTIONS.validate()),
          Cmd.action(PREVIEW_ACTIONS.setType()),
        ])
      );
    }

    default:
      return state;
  }
};
