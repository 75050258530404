export const getInitials = (user) => {
  const initials = {
    first: '',
    second: '',
  };

  if (user) {
    if (user.firstName) {
      initials.first = user.firstName.toUpperCase().charAt(0);
    }
    if (user.lastName) {
      initials.second = user.lastName.toUpperCase().charAt(0);
    }
  }

  return initials.first + initials.second;
};
