import * as PREVIEW_ACTIONS from '../../reduxLoop/actions/previewActions';
import { STATES, NEWSLETTER_TYPE } from '../../reduxLoop/static/constants';

require('./npViewOptions.scss');

class NpViewOptions {
  constructor($ngRedux, $uibModal, settings) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.STATES = STATES;
    this.$uibModal = $uibModal;
    this.settings = settings;
    this.NEWSLETTER_TYPE = NEWSLETTER_TYPE;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => ({
      viewOptions: state.viewOptions,
      valid: state.preview.valid,
      errors: state.preview.errors,
      sending: state.preview.sending,
      type: state.preview.type,
      isInterval: state.preview.intervalId,
      mails: state.mails.mails,
      loadingMails: state.mails.loading,
      isAllowedToSend: state.user.isAllowedToSend && !this.settings.demo,
      testPreviewSuccess: state.preview.testPreviewSuccess,
    }))(this);
  }

  async $onDestroy() {
    this.unsubscribe();
  }

  sendPreview() {
    if (this.valid && !(this.sending && this.isInterval)) {
      const modal = this.$uibModal.open({
        size: 'sm',
        template: `
          <div class="modal-body">
            <div class="content">
            Ben  je zeker?
            </div>
          </div>
          <div class="modal-footer">
            <div class="controls">
              <button class="btn btn-default btn-accept cancel" ng-click="cancel()">Annuleren</button>
              <button class="btn btn-default btn-accept" ng-click="accept()">Ja</button>
            </div>
          </div>
        `,
        backdrop: true,
        windowClass: 'confirm-send',
        controller: ($scope, $uibModalInstance) => {
          'ngInject';

          $scope.cancel = () => $uibModalInstance.dismiss({ $value: 'cancel' });
          $scope.accept = () => $uibModalInstance.close({ $value: 'accept' });
        },
      });

      modal.result.then(
        () => this.$ngRedux.dispatch(PREVIEW_ACTIONS.sendPreview()),
        () => {}
      );
    }
    this.isInterval = true;
  }

  sendTestPreview() {
    if (this.testMail && this.testMail !== '') {
      this.$ngRedux.dispatch(PREVIEW_ACTIONS.sendTestPreview(this.testMail));
    }
  }
}

export default {
  template: require('./npViewOptions.html'),
  controllerAs: 'ctrl',
  transclude: true,
  controller: NpViewOptions,
};
