// Compile directive: Allow angular to change HTML on the fly

const sanitize = require('sanitize-html');

export default [
  '$compile',
  'termHelper',
  'routerService',
  ($compile, termHelper, routerService) => {
    function sanitizeHtml(string, noBlankTarget) {
      const sanitizedString = sanitize(string, {
        allowedTags: [
          'b',
          'em',
          'strong',
          'a',
          'li',
          'ul',
          'ol',
          'p',
          'br',
          'table',
          'tr',
          'td',
          'tbody',
          'ws-term',
        ],
        allowedAttributes: {
          a: ['href', 'target', 'class', 'rel'],
          li: [],
          ul: ['class'],
          table: ['class'],
          'ws-term': ['ws-href'],
        },
        allowedClasses: {
          ul: ['list-basic-disc'],
          table: ['table', 'table-striped'],
          a: ['theme-content-link'],
        },
        selfClosing: ['br'],
        nonTextTags: ['style', 'script', 'textarea', 'noscript'],
        transformTags: {
          ul() {
            return {
              tagName: 'ul',
              attribs: {
                class: 'list-basic-disc',
              },
            };
          },
          /* br: function (tagName, attribs) {
          return {
            tagName: 'p',
          };
        }, */
          table() {
            return {
              tagName: 'table',
              attribs: {
                class: 'table table-striped',
              },
            };
          },
          a(tagName, attribs) {
            if (!attribs.href) {
              return {};
            }
            if (attribs.rel === 'term') {
              return {
                tagName: 'a',
                attribs: {
                  href: attribs.href,
                  rel: attribs.rel,
                },
              };
            }
            if (attribs.rel === 'webpage2-link') {
              const webpageInfo = routerService.getWebpageInfoFromHref(attribs.href);
              return {
                tagName: 'a',
                attribs: {
                  href: webpageInfo ? webpageInfo.path : attribs.href,
                  class: 'theme-content-link',
                },
              };
            }
            return {
              tagName: 'a',
              attribs: {
                target: noBlankTarget ? '_self' : '_blank',
                class: 'theme-content-link',
                href: routerService.getValidUrl(attribs.href),
              },
            };
          },
        },
      });
      return sanitizedString;
    }

    const filterHtml = (string) => {
      const sanitizedString = sanitize(string, {
        allowedTags: ['br', 'ul', 'ol', 'li', 'b'],
      });
      console.log('SANITIZED STRING:', sanitizedString);
      return sanitizedString;
    };

    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const useTerm = scope.$eval(attrs.compileTerm);
        const noHtml = scope.$eval(attrs.compileNoHtml);
        const noBlankTarget = scope.$eval(attrs.compileNoBlankTarget);
        let prevValue;
        scope.$watch(
          (scopeToEval) => scopeToEval.$eval(attrs.compile),
          (value) => {
            if (prevValue !== value) {
              if (typeof value !== 'undefined' && value !== null) {
                let newValue;
                if (noHtml) {
                  newValue = filterHtml(value);
                } else {
                  newValue = sanitizeHtml(value, noBlankTarget);
                }
                if (useTerm) {
                  if (useTerm === 'highlight') {
                    newValue = termHelper.highlightTerm(value);
                  } else {
                    newValue = termHelper.replaceTerm(value);
                  }
                } else {
                  newValue = termHelper.removeTerm(value);
                }
                element.html(newValue);
                $compile(element.contents())(scope);
              }
            }
          }
        );
      },
    };
  },
];
