/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as COMMANDS from '../commands/urlCommands';
import * as ACTIONS from '../actions/urlActions';
import * as VIEW_OPTIONS_ACTIONS from '../actions/viewOptionsActions';
import * as PREVIEW_ACTIONS from '../actions/previewActions';
import { isEmpty, mapSelected } from '../helpers/viewOptionsHelper';

const initialState = {
  coverage: null,
  positions: null,
  mainstructuresOuTypeCombinations: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_URL_PARAMS: {
      return loop(
        {
          ...state,
        },
        Cmd.run(COMMANDS.getUrlParamsCmd, {
          args: [],
          successActionCreator: ACTIONS.getUrlParamsSuccessful,
          failActionCreator: (error) => console.log('ERROR loading view options:', error),
        })
      );
    }

    case ACTION_TYPES.GET_URL_PARAMS_SUCCESSFUL: {
      const allEmpty = isEmpty(action.payload);
      let actions = [
        Cmd.action(VIEW_OPTIONS_ACTIONS.updateViewOptionsFromUrl(action.payload)),
        Cmd.action(PREVIEW_ACTIONS.setPreviewUrl(action.payload)),
      ];

      if (allEmpty) {
        actions = [Cmd.action(VIEW_OPTIONS_ACTIONS.loadPersonalViewOptions())];
      }

      return loop(
        {
          ...state,
          ...action.payload,
        },
        Cmd.list(actions)
      );
    }

    case ACTION_TYPES.SET_URL_PARAMS: {
      const params = mapSelected(action.payload);
      return loop(
        {
          ...state,
          ...params,
        },
        Cmd.run(COMMANDS.setUrlCmd, {
          args: [params],
        })
      );
    }

    case ACTION_TYPES.SET_URL_PARAM: {
      return loop(
        {
          ...state,
          [action.payload.param]: action.payload.values,
        },
        Cmd.run(COMMANDS.setUrlParamsCmd, {
          args: [action.payload],
        })
      );
    }

    default:
      return state;
  }
};
