import app from '../app/app';

app.component('appRoot', require('./appRoot'));
app.component('npNewsletter', require('./npNewsletter'));

app.component('npHeaderBar', require('./npHeaderBar'));
app.component('npPreview', require('./npPreview'));
app.component('npCheckboxList', require('./npCheckboxList'));

app.component('npViewOptions', require('./npViewOptions').default);
app.component('npCoverage', require('./npViewOptions/npCoverage').default);
app.component('npFunctions', require('./npViewOptions/npFunctions').default);
app.component('npMainstructuresOutype', require('./npViewOptions/npMainstructuresOutype').default);
app.component('npThemes', require('./npViewOptions/npThemes').default);
